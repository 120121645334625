import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import axios from "axios";
// import loadable from '@loadable/component';
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import FallbackComponent from "./components/FallbackComponent";
window.$ = window.jQuery = $;

const NotFound = React.lazy(() => import("./components/common/Notfound"));
const About = React.lazy(() => import("./components/employee/About"));
// const EmployeeLayout = React.lazy(() => import('./components/employee/Layout'))
// const EmployerLayout = React.lazy(() => import('./components/employer/layouts/Layout'))
const Home = React.lazy(() => import("./components/employee/Home"));
const Login = React.lazy(() => import("./components/employee/Login"));
const Signup = React.lazy(() => import("./components/employee/Signup"));
const SignupOtp = React.lazy(() => import("./components/employee/SignupOtp"));
const VerifyForgotOtp = React.lazy(() =>
  import("./components/employee/VerifyForgotOtp")
);
const ResetPassword = React.lazy(() =>
  import("./components/employee/ResetPassword")
);
const Forgot = React.lazy(() => import("./components/employee/ForgotPassword"));
const AllCategories = React.lazy(() =>
  import("./components/employee/AllCategories")
);
const BlogDetail = React.lazy(() => import("./components/employee/BlogDetail"));
const Test = React.lazy(() => import("./components/employee/InnerPages/Test"));

const EmployeeDashboard = React.lazy(() =>
  import("./components/employee/InnerPages/Dashboard")
);
const PostJob = React.lazy(() =>
  import("./components/employee/InnerPages/PostJob")
);
const EditJob = React.lazy(() =>
  import("./components/employee/InnerPages/EditJob")
);
const JobList = React.lazy(() =>
  import("./components/employee/InnerPages/JobList")
);
const JobDetail = React.lazy(() =>
  import("./components/employee/InnerPages/JobDetail")
);
const EmployeeProfile = React.lazy(() =>
  import("./components/employee/InnerPages/Profile")
);
const EmployeeEditProfile = React.lazy(() =>
  import("./components/employee/InnerPages/EditProfile")
);
const EmployeeSavedJobs = React.lazy(() =>
  import("./components/employee/InnerPages/SavedJobs")
);
const EmployeePosts = React.lazy(() =>
  import("./components/employee/InnerPages/posts/Posts")
);
const EmployeePostDetail = React.lazy(() =>
  import("./components/employee/InnerPages/posts/PostDetail")
);
const EmployeeMyPosts = React.lazy(() =>
  import("./components/employee/InnerPages/posts/MyPosts")
);
const EmployeePostedJobs = React.lazy(() =>
  import("./components/employee/InnerPages/PostedJobs")
);
const EmployeeAppliedJobs = React.lazy(() =>
  import("./components/employee/InnerPages/AppliedJobs")
);
const EmployeeImporveRating = React.lazy(() =>
  import("./components/employee/InnerPages/ImproveRating")
);
const EmpJobBookings = React.lazy(() =>
  import("./components/employee/InnerPages/JobBookings")
);
const EmployeeContactUs = React.lazy(() =>
  import("./components/employee/ContactUs")
);
const EmployeeSbmitPost = React.lazy(() =>
  import("./components/employee/InnerPages/posts/SubmitPost")
);
const EmployeeFollowers = React.lazy(() =>
  import("./components/employee/InnerPages/followers/followers")
);
const EmployeePendingRequests = React.lazy(() =>
  import("./components/employee/InnerPages/pending-requests/PendingRequests")
);

// Employer imports
const EmployerUpdateCompanyProfile = React.lazy(() =>
  import("./components/employer/InnerPages/UpdateCompanyProfile")
);
const EmployerAllProfile = React.lazy(() =>
  import("./components/employer/InnerPages/AllProfile")
);
const EmployerPayments = React.lazy(() =>
  import("./components/employer/InnerPages/Payments")
);
const Summary = React.lazy(() =>
  import("./components/employer/InnerPages/Summary")
);
const JobBookings = React.lazy(() =>
  import("./components/employer/InnerPages/JobBookings")
);
const SubEmployers = React.lazy(() =>
  import("./components/employer/InnerPages/SubEmployers")
);
const EmployerSbmitPost = React.lazy(() =>
  import("./components/employer/InnerPages/posts/SubmitPost")
);
const EmployerFollowers = React.lazy(() =>
  import("./components/employer/InnerPages/followers/followers")
);
const EmployerPendingRequests = React.lazy(() =>
  import("./components/employer/InnerPages/pending-requests/PendingRequests")
);
const EmployerPosts = React.lazy(() =>
  import("./components/employer/InnerPages/posts/Posts")
);
const EmployerMyPosts = React.lazy(() =>
  import("./components/employer/InnerPages/posts/MyPosts")
);
const SubemployerJobs = React.lazy(() =>
  import("./components/employer/InnerPages/SubemployerJobs")
);
const SubemployerView = React.lazy(() =>
  import("./components/employer/InnerPages/Subemployejobview")
);
//  Static pages
const PrivacyPolicy = React.lazy(() =>
  import("./components/employee/InnerPages/PrivacyPolicy")
);
const TermsConditions = React.lazy(() =>
  import("./components/employee/InnerPages/TermsConditions")
);
const AboutUs = React.lazy(() =>
  import("./components/employee/InnerPages/AboutUs")
);

const Advertisementpage = React.lazy(() =>
  import("./components/employer/InnerPages/Advertisement")
);

let navigate = null;
let location = null;
axios.defaults.baseURL = "https://pptransact.com/";
//  axios.defaults.baseURL = "http://172.16.102.231:3001/";
// axios.defaults.baseURL = 'https://webmobrildemo.com/obediant/';
//   axios.defaults.baseURL = "http://172.16.100.71:9700/";
// axios.defaults.baseURL = 'http://172.16.100.150:9700/';
//  axios.defaults.baseURL = "http://172.16.100.230:3000";

//  Request interceptor
axios.interceptors.request.use((request) => {
  let bearertoken = localStorage.getItem("transact_auth_user");
  if (bearertoken !== null) {
    bearertoken = JSON.parse(bearertoken).token;
  }
  // console.log('in request interceptor', bearertoken);
  request.headers["Authorization"] = "Bearer " + bearertoken;
  return request;
});
//  response interceptor
axios.interceptors.response.use(
  (response) => {
    // console.log("success part");
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("transact_auth_user");
        navigate("/");
      }
    }
    return Promise.reject(error);
  }
);

const ProtectedRoute = () => {
  navigate = useNavigate();
  location = useLocation();
  if (
    !localStorage.getItem("transact_auth_user") &&
    location.pathname !== "/employee/dashboard"
  ) {
    return <Navigate to="/employee/login" />;
  } else if (
    !localStorage.getItem("transact_auth_user") &&
    location.pathname === "/employee/dashboard"
  ) {
    return <Navigate to="/employee" />;
  }
  return <Outlet />;
};

const PublicRoute = () => {
  if (localStorage.getItem("transact_auth_user")) {
    return <Navigate to="/employee/dashboard" />;
  }
  return <Outlet />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    {/* <BrowserRouter> */}
    <Suspense fallback={<FallbackComponent />}>
      <Routes>
        <Route path="/" exact element={<Navigate to="/employee" replace />} />
        {/* Employee Routes Begins */}
        <Route path="employee" exact element={<Home />} />
        <Route path="test" exact element={<Test />} />
        <Route path="employee/about" element={<About />} />
        <Route path="employee/contact-us" element={<EmployeeContactUs />} />
        <Route path="all-categories" element={<AllCategories />} />
        <Route path="employee/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="employee/about-us" element={<AboutUs />} />
        <Route path="employee/terms-conditions" element={<TermsConditions />} />
        <Route element={<PublicRoute />}>
          <Route path="employee/login" element={<Login />} />
          <Route path="employee/signup" element={<Signup />} />
          <Route path="employee/signup/otp" element={<SignupOtp />} />
          <Route path="employee/forgot-password" element={<Forgot />} />
          <Route
            path="employee/forgot-password/otp"
            element={<VerifyForgotOtp />}
          />
          <Route path="employee/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="blog/:id" element={<BlogDetail />} />
          <Route path="employee/dashboard" element={<EmployeeDashboard />} />
          <Route path="employee/job-post" element={<PostJob />} />
          <Route path="employee/job-edit/:jobid" element={<EditJob />} />
          <Route path="employee/job-list" element={<JobList />} />
          <Route path="employee/job-detail/:jobid" element={<JobDetail />} />
          <Route
            path="employee/subjob-detail/:jobid"
            element={<SubemployerView />}
          />
          <Route path="employee/profile" element={<EmployeeProfile />} />
          <Route
            path="employee/edit-profile"
            element={<EmployeeEditProfile />}
          />
          <Route path="employee/saved-jobs" element={<EmployeeSavedJobs />} />
          <Route
            path="employee/applied-jobs"
            element={<EmployeeAppliedJobs />}
          />
          <Route path="employee/rating" element={<EmployeeImporveRating />} />
          <Route path="employee/bookings" element={<EmpJobBookings />} />
          <Route path="emloyee/Advertisement" element={<Advertisementpage />} />
          <Route path="employee/posts" element={<EmployeePosts />} />
          <Route path="employee/posts/submit" element={<EmployeeSbmitPost />} />
          <Route path="employee/myposts" element={<EmployeeMyPosts />} />
          <Route
            path="employee/post-detail/:postid"
            element={<EmployeePostDetail />}
          />
          <Route path="employee/myfollowers" element={<EmployeeFollowers />} />
          <Route
            path="employee/pending-requests"
            element={<EmployeePendingRequests />}
          />
          {/* <Route path="employe" element={<EmployeePendingRequests />} /> */}
        </Route>
        {/* Employee Routes Ends */}
        <Route element={<ProtectedRoute />}>
          {/* Employer Routes Begins */}
          <Route
            path="employer/profile"
            element={<EmployerUpdateCompanyProfile />}
          />
          <Route path="employer/all-profile" element={<EmployerAllProfile />} />
          <Route path="employer/payments" element={<EmployerPayments />} />
          <Route path="employer/summary" element={<Summary />} />
          <Route path="employer/bookings" element={<JobBookings />} />
          <Route path="employer/subemployer" element={<SubEmployers />} />
          <Route
            path="employer/subemployer-jobs"
            element={<SubemployerJobs />}
          />
          <Route path="employer/posted-jobs" element={<EmployeePostedJobs />} />
          <Route path="employer/posts" element={<EmployerPosts />} />
          <Route path="employer/myposts" element={<EmployerMyPosts />} />
          <Route
            path="employer/post-detail/:postid"
            element={<EmployeePostDetail />}
          />
          <Route path="employer/posts/submit" element={<EmployerSbmitPost />} />
          <Route path="employer/myfollowers" element={<EmployerFollowers />} />
          <Route
            path="employer/pending-requests"
            element={<EmployerPendingRequests />}
          />
          {/* Employer Routes Ends */}
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
